import React, {useEffect} from 'react';
import '../styles/TextSphere.css';
import TagCloud from 'TagCloud';


const TextSphere = () => {
    useEffect(() => { 
            const container = '.tagcloud';
            const texts = [
                "HTML",
                "CSS",
                "JavaScript",
                "React",
                "Next.js",
                "Node.js",
                "Express.js",
                "SQL",
                "Python",
                "Matplotlib",
                "Pandas",
                "Tableau",
                "Excel",
                "G. Analytics",
                "SPSS",
                "Git",
                "GitHub",
                "Docker",
                "WordPress",
                "Figma",
                "UI/UX",
                "Davinci Resolve"
            ];

            const options = {
                radius: 150,
                maxspeed: "normal",
                initSpeed: 'normal',
                keep: true,
            };
        
            TagCloud(container, texts, options);
        
    }, []);

    return (
        <>
            <div className='text-sphere w-full overflow-y-hidden'>
                <span className='tagcloud'></span>
            </div>
        </>
    );
};

export default TextSphere;