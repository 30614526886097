import React from 'react'

const Use = () => {
  return (
    
    <div name='use' className='w-full h-screen bg-[#131017] text-gray-300'>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br></br>
        <br/>
        <br/>
        <br></br>
        <br/>
        <br/>
        <br></br>
        <br/>
        <br/>
        <br></br>
        <br></br>
        <br/>
        <br/>
        <br></br>
        <br/>
        <br/>
        <br></br>
        <br></br>
        <br/>
        <br/>
        <br></br>
        <br/>
        <br/>
        <br></br>
        <br></br>
        <br/>
        <br/>
        <br></br>
        <div className='flex flex-col justify-center items-center w-full h-full'>
            <div className='max-w-[1000px] w-full grid grid-cols-2 gap-8'>
                <div className='sm pb-8 pl-4'>
                    <p className='text-4xl font-bold inline border-b-4 border-orange-600'>Tools</p>
                </div>
                <div>
                    
                </div>
            </div>
            <div className='max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4'>
                <div className='sm text-4xl font-bold'>
                    <p className='selection:bg-orange-600 text-sm'>LAST UPDATED: April 4, 2024.<br/></p>
                    <br/>
                    <p className='selection:bg-orange-600 text-lg'>People often inquire about the tools I use for work. Here's a list of some that you can try as well:
                    </p>
                </div>
                <div>

                    <section id="development">
                        <h2 className='text-xl'><b>Development & Analysis</b> 👩🏼‍💻</h2>
                        <hr></hr>
                        <br/>
                        <ul className="list-disc pl-5 text-base text-xl selection:bg-orange-600">
                            <li> I really like web development and use <a className='text-orange-500 no-underline hover:underline decoration-orange-600' target='blank' href="https://code.visualstudio.com/">Visual Studio Code</a> for writing and editing files.</li>
                            <li> I use <a className='text-orange-500 no-underline hover:underline decoration-orange-600' target='blank' href="https://react.dev/">React</a> and <a className='text-orange-500 no-underline hover:underline decoration-orange-600' target='blank' href="https://tailwindcss.com/">Tailwind CSS</a> to build things for the web. If you would like to know more about React, you can read <a className='text-orange-500 no-underline hover:underline decoration-orange-600' target='blank' href="https://www.linkedin.com/posts/shir-weinbrand_react-javascript-webdevelopment-activity-7069235995686113280-6LXp?utm_source=share&utm_medium=member_desktop">my post</a>.</li>
                            <li>To manage version control I use <a className='text-orange-500 no-underline hover:underline decoration-orange-600' target='blank' href="https://git-scm.com/">Git</a> and publish almost everything I write or develop on <a className='text-orange-500 no-underline hover:underline decoration-orange-600' target='blank' href="https://github.com/ShirWein">GitHub</a>.</li>
                            <li><a className='text-orange-500 no-underline hover:underline decoration-orange-600' target='blank' href="https://www.python.org/">Python</a>, <a className='text-orange-500 no-underline hover:underline decoration-orange-600' target='blank' href="https://pandas.pydata.org/">Pandas</a> and <a className='text-orange-500 no-underline hover:underline decoration-orange-600' target='blank' href="https://jupyter.org/">Jupyter</a> are my constant companions in my daily work.</li>
                            <li>I use RStudio for editing R files.</li>
                            <li>I perform statistical analyses using Pandas, SPSS and Stata.</li>
                            <li>As for terminal, <a className='text-orange-500 no-underline hover:underline decoration-orange-600' target='blank' href="https://iterm2.com/">iTerm2</a> is my favorite one. If you want to avoid staring at a black screen all day, check out <a className='text-orange-500 no-underline hover:underline decoration-orange-600' target='blank' href="https://www.linkedin.com/posts/shir-weinbrand_terminalcustomization-ohmyzsh-powerlevel10k-activity-7071055827310100480-2H6D?utm_source=share&utm_medium=member_desktop">my post</a> on customizing your terminal.</li>
                        </ul>
                    </section>
                    <br/>
                    <section id="design">
                        <h2 className='text-xl'><b>Design & Creativity</b> 🎨</h2>
                        <hr></hr>
                        <br/>
                        <ul className="list-disc pl-5 text-base text-xl selection:bg-orange-600">
                            <li>When it comes to design, I really adore Canva and FIgma.</li>
                            <li>I use <a className='text-orange-500 no-underline hover:underline decoration-orange-600' target='blank' href="https://excalidraw.com/">Excalidraw</a> as my whiteboard.</li>
                            <li>To visualize my analysis, I often use <a className='text-orange-500 no-underline hover:underline decoration-orange-600' target='blank' href="https://www.tableau.com/">Tableau</a> and <a className='text-orange-500 no-underline hover:underline decoration-orange-600' target='blank' href="https://gephi.org/">Gephi</a>.</li>
                        </ul>
                    </section>
                    <br/>
                    <section id="productivity">
                        <h2 className='text-xl'><b>Productivity</b> 🧠 </h2>
                        <hr></hr>
                        <br/>
                        <ul className="list-disc pl-5 text-base text-xl selection:bg-orange-600">
                            <li>Though paradoxically, I think the secret to being productive is not using any tool, <a className='text-orange-500 no-underline hover:underline decoration-orange-600' target='blank' href="https://www.youtube.com/watch?v=jfKfPfyJRdk">Lofi</a> helps with concentration, <a className='text-orange-500 no-underline hover:underline decoration-orange-600' target='blank' href="https://trello.com//">Trello</a> helps manage your tasks effectively and divide your big task into small blocks. </li>
                        </ul>
                    </section>
                    <br/>
                    <section id="hardware">
                        <h2 className='text-xl'><b>Hardware</b> 💻 </h2>
                        <hr></hr>
                        <br/>
                        <ul className="list-disc pl-5 text-base text-xl selection:bg-orange-600">
                            <li>A few years ago, I switched from Windows to Mac, and I couldn't be happier. Also, AirPods are an experience in themselves.</li>
                        </ul>
                    </section>
                    
                </div>
            </div>
        </div>
    </div>
  )
}

export default Use